<template>
  <div class="wrap">
    <el-scrollbar style="height:100%">
    <div class="content">
      <div class="fs-20 py-30">
        <strong>{{ edit ? '编辑文章' : '添加文章'}}</strong>
        <hr />
      </div>
      <div class="article-title fs-20">
        <div class="py-10">标题：</div>
        <input
          class="title-input fs-17 mb-10 pl-15"
          type="text"
          v-model="article.title"
          placeholder="请输入文章标题"
        />
      </div>
      <div class="article-title fs-20">
        <div class="py-10">简介：</div>
        <input
          class="title-input fs-17 mb-10 pl-15"
          type="text"
          v-model="article.synopsis"
          placeholder="文章涵盖的主要内容简单介绍"
        />
      </div>
      <div class="article-cate">
        <div class="py-10 fs-20">所属分类：</div>
        <div class="d-flex">
          <div
            class="categories-item fs-15 p-10 px-30 m-10 m-c-p"
            :class="{active: item.class}"
            v-for="item in categories"
            :key="item._id"
            @click="cate(item, $event)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="public">
          <div class="py-10 fs-20">是否公开：</div>
          <div class="d-flex">
              <div class="categories-item fs-15 p-10 px-30 m-10 m-c-p" :class="{active: publicOr}" @click="publicOr = !publicOr">是</div>
              <div class="categories-item fs-15 p-10 px-30 m-10 m-c-p" :class="{active: !publicOr}" @click="publicOr = !publicOr">否</div>
          </div>
      </div>
      <div class="article-content">
        <div class="py-10 fs-20">内容：</div>
        <vue-editor
          id="editor"
          useCustomImageHandler
          @image-added="handleImageAdded"
          v-model="article.content"
        ></vue-editor>
      </div>
      <div>
        <button
          class="add-article mt-20 px-30 py-10 fs-17 m-c-p"
          @click="addArticle"
        >
         {{ edit ? '确认修改' : '添加文章'}}
        </button>
        <span class="fs-15 ml-30 tip">{{ tip }}</span>
      </div>
    </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  props:['edit'],
  data() {
    return {
      categories: [],
      publicOr: false,
      tip:'',
      article: {
        title: "",
        content: "",
        userId: this.userid,
        categories: [],
        publicOr: '',
        synopsis:''
      },
    };
  },
  components: {
    VueEditor,
  },
  methods: {
    async getCategories() {
      const result = await this.$axios.get("/rest/Category");
      const temp = result.data.map((item) => {
        return {
          name: item.name,
          _id: item._id,
          class: this.classRes(item._id),
        };
      });
      this.categories = temp;
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      var formData = new FormData();
      formData.append("file", file);

      this.$axios({
        url: "/image/upload",
        method: "POST",
        data: formData,
      })
        .then((result) => {
          let url = result.data.url; // Get url from response
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cate(item, event) {
      if(Array.from(event.target.classList).indexOf('active') >= 0){
          event.target.classList.remove('active')
          this.article.categories = this.article.categories.filter(ele => ele != item._id)
      }else{
          event.target.classList.add('active')
          this.article.categories.push(item._id)
      }
      
    },
    classRes(id) {
      return this.article.categories.indexOf(id) >= 0;
    },
    async addArticle(){
        if(!this.article.title){
            this.tip = "× 请输入标题"
            setTimeout(() => {this.tip = ''}, 3000)
            return
        }else if(!this.article.content){
            this.tip = "× 请输入内容"
            setTimeout(() => {this.tip = ''}, 3000)
            return
        }
        const model = Object.assign({}, this.article, {userId: this.userid, publicOr: this.publicOr})
        if(!this.edit){
          this.$axios.post('/addArticle', model)
          this.$router.push('/client')
        }else{
          const result = await this.$axios.post(`/articleUpdate/${this.edit}`, model)
          this.$message({type: 'success',message: '修改成功'})
          this.$router.push('/user')
        }
    },
    async getArticleInfo(){
      const result = await this.$axios.get(`/rest/Article/${this.edit}`)
      this.article = result.data
    }
  },
  computed:{
      userid(){
          return this.$store.state.user._id
      },
  },
  mounted() {
    // 获取文章分类信息
    this.getCategories()
    this.edit && this.getArticleInfo()
  },
  watch:{
    article(value){
      value.categories = value.categories ? value.categories : []
      const includeCate = value.categories.map(item => item.name)
      this.categories.filter((item, index) => {
        if(includeCate.indexOf(item.name) > -1){
          this.$set(this.categories[index], 'class', true)
        }
      })
      this.publicOr = value.publicOr
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/css/variables";
.wrap {
  margin-top: 5rem;
  height: 100vh;
  .content {
    width: 140rem;
    margin: 0 auto;
    transform: translate(-9rem, 0);
    margin-bottom: 5rem;
    .article-title {
      .title-input {
        border: none;
        height: 5rem;
        width: 100rem;
        border: 1px solid map-get($colors, "border-grey");
        border-radius: 1rem;
      }
    }
    .categories-item {
        border: 1px solid map-get($colors, "border-light");
        border-radius: 0.3rem;
        &.active {
            background: map-get($colors, "purple");
            color: white;
            border: 1px solid transparent;
        }
    }
    
    .add-article {
      background: map-get($colors, "purple");
      border: none;
      color: white;
      border-radius: 0.5rem;
    }
  }
  .tip{
      color: red;
  }
}
</style>